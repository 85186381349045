import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Fab from "@material-ui/core/Fab";
import { withSnackbar } from "notistack";

import { translate } from "react-multi-lang";

import AddIcon from "@material-ui/icons/Add";
import moment from "moment";

import withAuth from "../components/auth/withAuth";

import fetchClient from "../components/utils/fetchClient";

const styles = theme => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        padding: theme.spacing()
    },
    fab: {
        margin: theme.spacing(1),
        top: "auto",
        right: 10,
        bottom: 10,
        left: "auto",
        position: "fixed"
    }
});

class ReportsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reports: [],
            isLoading: true,
            currentPropertyId: this.props.match.params.id
        };
    }

    async componentDidMount() {
        let reports = await this.fetchReports();

        this.setState({
            reports: reports,
            isLoading: false
        });
    }

    async fetchReports() {
        var self = this;
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("/cr/reports/list/" + self.props.match.params.id)
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    createReport = () => {
        var self = this;
        fetchClient.post("/cr/reports/new", { propertyId: self.props.match.params.id }).then(function (response) {
            if (response.data.success) {
                self.props.history.push(`/reports/edit/${response.data.data.reportId}`);
            } else {
                self.props.enqueueSnackbar(response.data.message, { variant: "error", preventDuplicate: true });
            }
        });
    };

    render() {
        const { classes, t } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="sticky">
                    <Toolbar>
                        <IconButton
                            onClick={() => {
                                this.props.history.push("/");
                            }}
                            className={classes.menuButton}
                            color="inherit"
                            aria-label={t("misc.Back")}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            {!this.state.isLoading
                                ? t("titles.ViewReports", {
                                      param: this.state.reports.property.propertyName
                                  })
                                : t("titles.Loading") + "..."}
                        </Typography>
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}></div>
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                    {!this.state.isLoading ? (
                        <div>
                            <Typography variant="h5" color="inherit" className={classes.grow}>
                                {this.state.reports.property.propertyName}
                            </Typography>
                            <div>
                                <Typography variant="h6" color="inherit" className={classes.grow}>
                                    {t("reports.PreviousReports")}
                                </Typography>
                                <List>
                                    {this.state.reports.reports.map(report => {
                                        return (
                                            <ListItem
                                                key={report.reportId}
                                                button
                                                component="a"
                                                onClick={() => {
                                                    this.props.history.push("/reports/edit/" + report.reportId);
                                                }}
                                            >
                                                <ListItemText primary={moment(report.reportDate).format("DD/MM/YYYY HH:mm") + " " + report.checkedByName} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                                <Fab color="primary" aria-label={t("misc.Add")} className={classes.fab} onClick={this.createReport}>
                                    <AddIcon />
                                </Fab>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

ReportsList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default translate(withAuth(withSnackbar(withStyles(styles)(ReportsList))));
