import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import SendIcon from "@material-ui/icons/Send";
import SaveIcon from "@material-ui/icons/Save";
import ArrowBack from "@material-ui/icons/ArrowBack";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CloseIcon from "@material-ui/icons/Close";
import withAuth from "../components/auth/withAuth";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Fade from "@material-ui/core/Fade";
import TextFieldMUI from "@material-ui/core/TextField";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TextField } from "final-form-material-ui";

import { translate } from "react-multi-lang";

import { withSnackbar } from "notistack";

import { DragDrop, StatusBar } from "@uppy/react";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import ImageCompressor from "uppy-plugin-image-compressor";

import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";

import fetchClient from "../components/utils/fetchClient";
import convertToBooleanValue from "../components/utils/formUtilities";

function Title(props) {
    return (
        <Typography variant="h6" paragraph>
            {props.input.value}
        </Typography>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

const updateValue = ([name, newvalue], state, { changeValue }) => {
    changeValue(state, name, value => newvalue);
};

const renderCheckbox = ({ input, label }) => {
    let val = convertToBooleanValue(input.checked);
    return (
        <div>
            <FormControlLabel control={<Checkbox checked={val} onChange={input.onChange} />} label={label} />
        </div>
    );
};

class renderUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentFile: "",
            dialogOpen: false
        };

        this.showModal = this.showModal.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.picuploader = new Uppy({
            id: "uploader" + this.props.index,
            autoProceed: true
        })
            .use(ImageCompressor, {
                maxWidth: 1000,
                quality: 0.6
            })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "cleaning_report",
                                upload_type: "report",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp) => {
                this.props.mutators.updateValue(`sections.${this.props.parentIndex}.items.${this.props.index}.itemImages`, [...this.props.input.value, resp.uploadURL]);
            });
    }

    componentWillUnmount() {
        this.picuploader.close();
    }

    showModal(image) {
        if (image) {
            this.setState({ currentFile: image, dialogOpen: true });
        }
    }

    render() {
        const { picuploader } = this;
        const { label, t, classes } = this.props;

        return (
            <div>
                <Typography variant="h6" color="inherit">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: label
                        }}
                    ></span>
                </Typography>

                <DragDrop
                    uppy={picuploader}
                    locale={{
                        strings: {
                            dropHereOr: t("report.Upload") + " %{browse}",
                            browse: t("report.Browse")
                        }
                    }}
                />
                <StatusBar uppy={picuploader} hideUploadButton hideAfterFinish={true} showProgressDetails />

                <div style={{ paddingBottom: 10 }}>
                    {this.props.input.value && this.props.input.value.length > 0
                        ? this.props.input.value.map((image, index) => (
                              <img
                                  key={index}
                                  style={{
                                      paddingLeft: 2,
                                      paddingRight: 2
                                  }}
                                  height="50"
                                  width="50"
                                  className="uploaded-image"
                                  src={image}
                                  alt={image.split("/").pop()}
                                  onClick={() => this.showModal(image)}
                              />
                          ))
                        : null}
                </div>
                <Dialog
                    fullScreen
                    open={this.state.dialogOpen}
                    onClose={() => {
                        this.setState({
                            dialogOpen: false
                        });
                    }}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        dialogOpen: false
                                    });
                                }}
                                color="inherit"
                                className={classes.menuButton}
                                aria-label="Close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                {t("report.ViewImage")}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div
                        style={{
                            paddingLeft: 15,
                            paddingTop: 15,
                            paddingRight: 15
                        }}
                    >
                        <img
                            style={{
                                width: "100%",
                                height: "auto"
                            }}
                            src={this.state.currentFile}
                            alt={this.state.currentFile.split("/").pop()}
                        />
                    </div>
                </Dialog>
            </div>
        );
    }
}

const styles = theme => ({
    root: {
        width: "100%",
        paddingBottom: 10
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    content: {
        padding: theme.spacing(),
        margin: theme.spacing()
    },
    flex: {
        flex: 1
    },
    appBar: {
        position: "relative"
    },
    paper: {
        marginBottom: 50
    },
    formControl: {
        margin: theme.spacing(),
        minWidth: 300
    },
    buttonSaving: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    }
});

let submit;

class EditReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            report: [],
            isLoading: true,
            isDisabled: false,
            activeStep: 0,
            totalSteps: 0,
            newTaskOpen: false,
            newTask: {
                taskDesc: "",
                taskPriority: 0,
                taskFiles: []
            },
            formValues: [],
            submitDialogOpen: false,
            submitReport: {
                sendToSupervisor: false,
                sendToCleaner: false,
                sendToComment: ""
            }
        };

        this.submitIssue = this.submitIssue.bind(this);
        this.saveReport = this.saveReport.bind(this);
        this.closeSaveReport = this.closeSaveReport.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSendToChange = this.handleSendToChange.bind(this);
        this.handleSendToChangeComment = this.handleSendToChangeComment.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.taskimageuploader = new Uppy({ id: "maimage", autoProceed: true })
            .use(ImageCompressor, {
                maxWidth: 1000,
                quality: 0.6
            })
            .use(AwsS3, {
                getUploadParameters(file) {
                    return fetchClient
                        .post(
                            "upload",
                            JSON.stringify({
                                destination: "ma",
                                upload_type: "ma",
                                filename: file.name,
                                contentType: file.type
                            })
                        )
                        .then(response => {
                            return response.data;
                        })
                        .then(data => {
                            return {
                                method: data.method,
                                url: data.url,
                                fields: data.fields,
                                resultId: data.resultId
                            };
                        });
                }
            })
            .on("upload-success", (file, resp) => {
                this.setState({
                    newTask: {
                        ...this.state.newTask,
                        taskFiles: [...this.state.newTask.taskFiles, resp.uploadURL]
                    }
                });
            });
    }

    componentWillUnmount() {
        this.taskimageuploader.close();
    }

    async componentDidMount() {
        let report = await this.fetchReport();

        this.setState({
            report: report.report,
            isLoading: false,
            totalSteps: report.report.sections.length - 1
        });
    }

    async fetchReport() {
        var self = this;
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("/cr/reports/view/" + self.props.match.params.id)
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    handleClickOpen = () => {
        this.setState({ newTaskOpen: true });
    };

    handleClose = () => {
        this.setState({ newTaskOpen: false });
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1
        }));
    };

    handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1
        }));
    };

    handleChange = event => {
        this.setState({
            newTask: {
                ...this.state.newTask,
                [event.target.name]: event.target.value
            }
        });
    };

    handleSendToChange = event => {
        this.setState({
            submitReport: {
                ...this.state.submitReport,
                [event.target.name]: event.target.checked
            }
        });
    };

    closeSaveReport = () => {
        this.setState({ submitDialogOpen: false });
    };

    handleSendToChangeComment = event => {
        this.setState({
            submitReport: {
                ...this.state.submitReport,
                [event.target.name]: event.target.value
            }
        });
    };

    submitIssue() {
        var self = this;

        self.setState({ isDisabled: true });

        fetchClient
            .post("ma/new", {
                ...self.state.newTask,
                propertyId: self.state.report.property.propertyId
            })
            .then(function (response) {
                self.setState({
                    newTask: {
                        ...self.state.newTask,
                        taskPriority: 0,
                        taskDesc: "",
                        taskFiles: []
                    },
                    newTaskOpen: false,
                    isDisabled: true
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    save = async values => {
        var self = this;

        self.setState({ isDisabled: true });

        await this.saveReport(values);

        self.setState({ isDisabled: false });
    };

    handleSave = async t => {
        await submit();
        this.props.enqueueSnackbar(t("report.SaveSuccess"), { variant: "success", preventDuplicate: true });
    };

    async saveReport(values) {
        var self = this;
        return new Promise(function (resolve, reject) {
            fetchClient
                .post("/cr/reports/update/" + self.props.match.params.id, values)
                .then(function (response) {
                    resolve();
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    finalise = async t => {
        var self = this;

        await submit();

        self.setState({ isDisabled: true });

        await this.finaliseReport();

        self.setState({ isDisabled: false });

        this.closeSaveReport();

        this.props.enqueueSnackbar(t("report.SendSuccess"), { variant: "success", preventDuplicate: true });
    };

    handleFinalise = () => {
        this.setState({ submitDialogOpen: true });
    };

    async finaliseReport() {
        var self = this;
        return new Promise(function (resolve, reject) {
            fetchClient
                .post("/cr/reports/finalise/" + self.props.match.params.id, { ...self.state.submitReport })
                .then(function (response) {
                    resolve();
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    render() {
        const { classes, t, theme } = this.props;

        const { taskimageuploader } = this;

        return (
            <div className={classes.root}>
                <AppBar position="sticky">
                    <Toolbar>
                        <IconButton
                            onClick={() => {
                                this.props.history.push("/reports/" + this.state.report.property.propertyId);
                            }}
                            className={classes.menuButton}
                            color="inherit"
                            aria-label={t("misc.Back")}
                        >
                            <ArrowBack />
                        </IconButton>
                        <Typography variant="h6" color="inherit" className={classes.grow}>
                            {!this.state.isLoading
                                ? t("titles.EditReport", {
                                      param: this.state.report.property.propertyName
                                  })
                                : t("titles.Loading") + "..."}
                        </Typography>
{/*                         <IconButton onClick={this.handleClickOpen} className={classes.menuButton} color="inherit" aria-label={t("report.NewTask")}>
                            <FontAwesomeIcon icon="tools" size="sm" />
                        </IconButton> */}
                        <IconButton onClick={() => this.handleSave(t)} className={classes.menuButton} disabled={this.state.isDisabled} color="inherit" aria-label={t("report.Save")}>
                            <SaveIcon />
                        </IconButton>
                        <IconButton onClick={this.handleFinalise} className={classes.menuButton} disabled={this.state.isDisabled} color="inherit" aria-label={t("report.Send")}>
                            <SendIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                    {!this.state.isLoading ? (
                        <Paper className={classes.paper}>
                            <Form
                                onSubmit={this.save}
                                mutators={{
                                    ...arrayMutators,
                                    updateValue
                                }}
                                initialValues={this.state.report}
                                render={({ handleSubmit, form: { mutators } }) => {
                                    submit = handleSubmit;

                                    return (
                                        <form onSubmit={handleSubmit} style={{ padding: 10 }}>
                                            <FieldArray name="sections">
                                                {({ fields }) => (
                                                    <div>
                                                        {fields.map((section, parentIndex) => (
                                                            <div key={parentIndex}>
                                                                {this.state.activeStep === parentIndex && (
                                                                    <div>
                                                                        <Field name={`${section}.name`} component={Title} />

                                                                        <div>
                                                                            <FieldArray name={`${section}.items`}>
                                                                                {({ fields }) => (
                                                                                    <div>
                                                                                        {fields.map((items, index) => (
                                                                                            <div key={index}>
                                                                                                <div>
                                                                                                    <Field
                                                                                                        name={`${items}.itemImages`}
                                                                                                        mutators={mutators}
                                                                                                        t={t}
                                                                                                        classes={classes}
                                                                                                        parentIndex={parentIndex}
                                                                                                        index={index}
                                                                                                        component={renderUploader}
                                                                                                    />

                                                                                                    <Field
                                                                                                        name={`${items}.itemContent`}
                                                                                                        label={t("report.Content")}
                                                                                                        className={classes.formControl}
                                                                                                        multiline
                                                                                                        component={TextField}
                                                                                                    />

                                                                                                    <Field
                                                                                                        name={`${items}.itemPositive`}
                                                                                                        label={t("report.Positive")}
                                                                                                        type="checkbox"
                                                                                                        className={classes.formControl}
                                                                                                        component={renderCheckbox}
                                                                                                    />
                                                                                                </div>

                                                                                                <Button
                                                                                                    style={{
                                                                                                        marginTop: 10
                                                                                                    }}
                                                                                                    variant="contained"
                                                                                                    color="primary"
                                                                                                    onClick={() => fields.remove(index)}
                                                                                                >
                                                                                                    {t("report.RemoveItem")}
                                                                                                </Button>
                                                                                            </div>
                                                                                        ))}
                                                                                        <Button
                                                                                            variant="contained"
                                                                                            color="primary"
                                                                                            style={{
                                                                                                marginTop: 10
                                                                                            }}
                                                                                            onClick={() =>
                                                                                                fields.push({
                                                                                                    itemImages: [],
                                                                                                    itemContent: "",
                                                                                                    itemPositive: false
                                                                                                })
                                                                                            }
                                                                                        >
                                                                                            {t("report.AddItem")}
                                                                                        </Button>
                                                                                    </div>
                                                                                )}
                                                                            </FieldArray>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </FieldArray>
                                            <MobileStepper
                                                variant="progress"
                                                steps={this.state.totalSteps + 1}
                                                position="bottom"
                                                activeStep={this.state.activeStep}
                                                nextButton={
                                                    <Button size="small" onClick={this.handleNext} disabled={this.state.activeStep === this.state.totalSteps}>
                                                        {t("report.Next")}

                                                        {this.state.activeStep !== this.state.totalSteps && <>{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}</>}
                                                    </Button>
                                                }
                                                backButton={
                                                    <Button size="small" onClick={this.handleBack} disabled={this.state.activeStep === 0}>
                                                        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                                        {t("report.Back")}
                                                    </Button>
                                                }
                                            />
                                        </form>
                                    );
                                }}
                            />
                        </Paper>
                    ) : null}
                </div>
                <Dialog fullScreen open={this.state.newTaskOpen} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label={t("misc.Close")}>
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                {t("report.NewTask")}
                            </Typography>
                            <Button color="inherit" disabled={!this.state.newTask.taskDesc || this.state.isDisabled} onClick={this.submitIssue}>
                                {t("report.SubmitTask")}
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.content}>
                        <FormControl fullWidth>
                            <TextFieldMUI
                                required
                                label={t("report.TaskLabel")}
                                multiline
                                onChange={this.handleChange}
                                name="taskDesc"
                                value={this.state.newTask.taskDesc}
                                error={!Boolean(this.state.newTask.taskDesc)}
                            />
                        </FormControl>
                        <br /> <br />
                        <FormControl required fullWidth>
                            <InputLabel htmlFor="taskPriority">{t("report.TaskPriority")}</InputLabel>
                            <Select
                                native
                                value={this.state.newTask.taskPriority}
                                onChange={this.handleChange}
                                inputProps={{
                                    name: "taskPriority",
                                    id: "taskPriority"
                                }}
                            >
                                <option value={0}>{t("report.TaskPriorityLow")}</option>
                                <option value={1}>{t("report.TaskPriorityMedium")}</option>
                                <option value={2}>{t("report.TaskPriorityHigh")}</option>
                            </Select>
                        </FormControl>
                        <DragDrop
                            uppy={taskimageuploader}
                            locale={{
                                strings: {
                                    dropHereOr: t("report.Upload") + " %{browse}",
                                    browse: t("report.Browse")
                                }
                            }}
                        />
                        <StatusBar uppy={taskimageuploader} hideUploadButton hideAfterFinish={true} showProgressDetails />
                        {this.state.newTask.taskFiles.map((img, index) => {
                            return <img height="50" width="50" key={index} src={img} alt={img.split(".com/").pop()} className="uploaded-image" />;
                        })}
                    </div>
                </Dialog>

                <Dialog aria-labelledby="sendto-dialog-title" open={this.state.submitDialogOpen}>
                    <DialogTitle id="sendto-dialog-title">{t("report.SendTo")}</DialogTitle>
                    <DialogContent>
                       {/*  <FormControlLabel
                            control={<Checkbox checked={this.state.submitReport.sendToCleaner} />}
                            name="sendToCleaner"
                            onChange={this.handleSendToChange}
                            label={t("report.SendToCleaner")}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.submitReport.sendToSupervisor} />}
                            name="sendToSupervisor"
                            onChange={this.handleSendToChange}
                            label={t("report.SendToSupervisor")}
                        /> */}

                        <TextFieldMUI name="sendToComment" value={this.state.submitReport.sendToComment} onChange={this.handleSendToChangeComment} fullWidth label={t("report.SendToComment")} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeSaveReport}>{t("misc.Close")}</Button>
                        <Button onClick={() => this.finalise(t)} color="primary" disabled={this.state.isDisabled}>
                            {t("report.Submit")}
                            {this.state.isDisabled && <CircularProgress size={24} className={classes.buttonSaving} />}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

EditReport.propTypes = {
    classes: PropTypes.object.isRequired
};

export default translate(withAuth(withSnackbar(withStyles(styles, { withTheme: true })(EditReport))));
