import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { SnackbarProvider } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { setDefaultTranslations, setDefaultLanguage, setLanguage } from "react-multi-lang";
import es from "./translations/es.json";
import en from "./translations/en.json";

setDefaultTranslations({ es, en });
setDefaultLanguage("en");

var crSettings = JSON.parse(localStorage.getItem("crSettings"));
if (crSettings) {
    if (crSettings.language) {
        setLanguage(crSettings.language);
    }
} else {
    localStorage.setItem("crSettings", JSON.stringify({ language: "en" }));
}

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
    <SnackbarProvider
        ref={notistackRef}
        action={key => (
            <IconButton style={{ color: "white" }} onClick={onClickDismiss(key)}>
                <CloseIcon />
            </IconButton>
        )}
        anchorOrigin={{
            vertical: "top",
            horizontal: "center"
        }}
    >
        <App />
    </SnackbarProvider>,
    document.getElementById("root")
);
