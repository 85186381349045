import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@material-ui/icons/Search";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import FilterList from "@material-ui/icons/FilterList";
import ClearIcon from "@material-ui/icons/Clear";
import MenuIcon from "@material-ui/icons/Menu";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import RateReview from "@material-ui/icons/RateReview";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Badge from "@material-ui/core/Badge";
import { fade } from "@material-ui/core/styles/colorManipulator";
import InputBase from "@material-ui/core/InputBase";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import ReactSelect from "react-select";

import { translate } from "react-multi-lang";

import AuthService from "../components/auth/AuthService";
import withAuth from "../components/auth/withAuth";

import fetchClient from "../components/utils/fetchClient";

const Auth = new AuthService();

function Select({ defaultValue, options, ...otherProps }) {
    return (
        <ReactSelect
            defaultValue={
                defaultValue && {
                    value: defaultValue,
                    label: options.find(o => o.value === defaultValue).label
                }
            }
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: "#b967c7",
                    primary50: "#af52bf",
                    primary75: "#a53cb7",
                    primary: "#9C27B0"
                }
            })}
            options={options}
            {...otherProps}
        />
    );
}

const styles = theme => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    headerIcon: {
        marginRight: 5
    },
    drawer: {
        width: 250
    },
    drawerInput: {
        margin: theme.spacing()
    },
    divider: {
        backgroundColor: theme.palette.primary.main
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        width: "100%",
        [theme.breakpoints.up("xs")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        width: theme.spacing(9),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit",
        width: "100%"
    },
    searchInput: {
        paddingTop: theme.spacing(),
        paddingRight: theme.spacing(),
        paddingBottom: theme.spacing(),
        paddingLeft: theme.spacing(8),
        transition: theme.transitions.create("width"),
        width: "100%"
    }
});

class PropertiesList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            initialPropertiesList: [],
            propertiesList: [],
            filters: {
                viewType: 0,
                viewBuilding: 0,
                onlyAuto: 0,
                viewSearch: ""
            },
            drawerOpen: false,
            filtersCount: 0,
            buildingsList: []
        };

        var decoded = Auth.getProfile();
        this.state.profileId = decoded.profileId;
        this.state.accessLevel = decoded.accessLevel;
        this.state.userName = decoded.userName;

        this.logout = this.logout.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.refetchProperties = this.refetchProperties.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.filterLists = this.filterLists.bind(this);
        this.clearSearch = this.clearSearch.bind(this);

        var crViewConfig = JSON.parse(localStorage.getItem("crViewConfig"));
        if (crViewConfig) {
            Object.keys(crViewConfig).forEach(key => {
                this.state.filters[key] = crViewConfig[key];
            });
        }
    }

    logout() {
        Auth.logout();
        this.props.history.replace("/login");
    }

    toggleDrawer = open => () => {
        this.setState({
            drawerOpen: open
        });

        if (!open) {
            this.handleMenuClose();
        }
    };

    handleMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    filterLists(event) {
        var updatedList = this.state.initialPropertiesList;
        updatedList = updatedList.filter(function (item) {
            return item.propertyName.toLowerCase().search(event.target.value.toLowerCase()) !== -1;
        });
        this.setState({
            propertiesList: updatedList,
            filters: {
                ...this.state.filters,
                viewSearch: event.target.value
            }
        });
    }

    clearSearch() {
        this.setState(
            {
                propertiesList: this.state.initialPropertiesList,
                filters: {
                    ...this.state.filters,
                    viewSearch: ""
                }
            },
            () => {
                localStorage.setItem("crViewConfig", JSON.stringify(this.state.filters));
            }
        );
    }

    async componentDidMount() {
        let properties = await this.fetchProperties();
        let buildings = await this.fetchBuildings();

        this.setState({
            initialPropertiesList: properties,
            propertiesList: properties,
            buildingsList: buildings,
            filtersCount: this.countFilters(),
            isLoading: false
        });
    }

    async refetchProperties() {
        let properties = await this.fetchProperties();

        this.setState({
            initialPropertiesList: properties,
            propertiesList: properties,
            filtersCount: this.countFilters(),
            isLoading: false
        });
    }

    async fetchProperties() {
        var self = this;
        return new Promise(function (resolve, reject) {
            fetchClient
                .post("cr/properties", self.state.filters)
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async fetchBuildings() {
        return new Promise(function (resolve, reject) {
            fetchClient
                .get("buildings")
                .then(function (response) {
                    resolve(response.data.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    handleToggle = event => {
        this.setState(
            {
                isLoading: true,
                filters: {
                    ...this.state.filters,
                    viewType: event.target.checked ? 1 : 0
                },
                drawerOpen: false
            },
            () => {
                localStorage.setItem("crViewConfig", JSON.stringify(this.state.filters));
                this.handleMenuClose();
                this.refetchProperties();
            }
        );
    };

    handleSelect = (val, event) => {
        this.setState(
            {
                isLoading: true,
                filters: {
                    ...this.state.filters,
                    [event.name]: val ? val.value : null
                },
                drawerOpen: false
            },
            () => {
                localStorage.setItem("crViewConfig", JSON.stringify(this.state.filters));
                this.handleMenuClose();
                this.refetchProperties();
            }
        );
    };

    countFilters() {
        let count = 0;
        Object.keys(this.state.filters).forEach(key => {
            if (key !== "viewSearch") {
                if ((isNaN(this.state.filters[key]) && this.state.filters[key] !== "0") || (!isNaN(this.state.filters[key]) && this.state.filters[key] > 0)) {
                    count++;
                }
            }
        });

        return count;
    }

    render() {
        const { anchorEl } = this.state;
        const { classes, t } = this.props;
        const isMenuOpen = Boolean(anchorEl);

        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
            >
                <MenuItem className={classes.sectionMobile} onClick={this.toggleDrawer(true)}>
                    <p>
                        {t("nav.Filter")} {this.state.filtersCount > 0 ? "(" + this.state.filtersCount + ")" : null}{" "}
                    </p>
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        this.props.history.push("/settings/");
                    }}
                >
                    <p>{t("nav.Settings")}</p>
                </MenuItem>

                <MenuItem onClick={this.logout}>
                    <p>{t("nav.Logout", { param: this.state.userName })}</p>
                </MenuItem>
            </Menu>
        );

        return (
            <div className={classes.root}>
                <AppBar position="sticky">
                    <Toolbar>
                        <RateReview className={classes.headerIcon} />
                        <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                            cleaningReportApp
                        </Typography>

                        <div className={classes.grow}>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    value={this.state.filters.viewSearch}
                                    onChange={this.filterLists}
                                    placeholder={t("list.Search") + "..."}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.searchInput
                                    }}
                                    endAdornment={
                                        this.state.filters.viewSearch.length > 0 && (
                                            <InputAdornment position="end">
                                                <IconButton aria-label={t("list.Clear")} onClick={this.clearSearch}>
                                                    <ClearIcon style={{ color: "white" }} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }
                                />
                            </div>
                        </div>

                        <div className={classes.sectionDesktop}>
                            <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={this.toggleDrawer(true)} color="inherit">
                                <Badge badgeContent={this.state.filtersCount} color="secondary">
                                    <FilterList />
                                </Badge>
                            </IconButton>
                        </div>

                        <IconButton aria-owns={isMenuOpen ? "material-appbar" : undefined} aria-haspopup="true" onClick={this.handleMenuOpen} color="inherit">
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                {renderMenu}
                {this.state.isLoading && <LinearProgress color="secondary" />}

                <List>
                    {!this.state.isLoading &&
                        this.state.propertiesList.map(property => {
                            return (
                                <div key={property.propertyId}>
                                    <ListItem
                                        button
                                        component="a"
                                        onClick={() => {
                                            this.props.history.push("/reports/" + property.propertyId);
                                        }}
                                    >
                                        <ListItemText disableTypography primary={<Typography type="h6">{property.propertyName}</Typography>} />
                                    </ListItem>
                                    <Divider className={classes.divider} />
                                </div>
                            );
                        })}
                </List>

                <SwipeableDrawer anchor="right" open={this.state.drawerOpen} onClose={this.toggleDrawer(false)} onOpen={this.toggleDrawer(true)}>
                    <div tabIndex={0} role="button" className={classes.drawer}>
                        <div className={classes.drawerInput}>
                            <Typography variant="h6" color="inherit">
                                {t("list.Building")}
                            </Typography>

                            <Select
                                isClearable
                                name="viewBuilding"
                                options={this.state.buildingsList.map(option => ({
                                    value: option.buildingId,
                                    label: option.buildingName
                                }))}
                                defaultValue={this.state.filters.viewBuilding}
                                classNamePrefix="react-select"
                                onChange={this.handleSelect}
                            />
                        </div>
                        <div className={classes.drawerInput}>
                            <Typography variant="h6" color="inherit">
                                {t("list.Rating")}
                            </Typography>

                            <Select
                                isClearable
                                name="viewType"
                                options={[
                                    {
                                        value: 1,
                                        label: t("list.RatingWorst30Days")
                                    },
                                    {
                                        value: 2,
                                        label: t("list.RatingWorst1Year")
                                    }
                                ]}
                                defaultValue={this.state.filters.viewType}
                                classNamePrefix="react-select"
                                onChange={this.handleSelect}
                            />
                        </div>

                        <div className={classes.drawerInput}>
                            <Typography variant="h6" color="inherit">
                                {t("list.Auto")}
                            </Typography>
                            <FormControlLabel
                                control={<Switch checked={Boolean(this.state.filters.onlyAuto)} onChange={this.handleToggle} value="onlyAuto" color="primary" />}
                                label={t("list.OnlyAuto")}
                            />
                        </div>
                    </div>
                </SwipeableDrawer>
            </div>
        );
    }
}

PropertiesList.propTypes = {
    classes: PropTypes.object.isRequired
};

export default translate(withAuth(withStyles(styles)(PropertiesList)));
