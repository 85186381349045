import React, { Component } from "react";
import { Route, Switch, HashRouter, Redirect } from "react-router-dom";
import "./App.css";
import * as serviceWorker from "./serviceWorker";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import ReportsList from "./components/ReportsList";
import Login from "./components/Login";
import Settings from "./components/Settings";
import EditReport from "./components/EditReport";
import PropertiesList from "./components/PropertiesList";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

const theme = createMuiTheme({
    palette: {
        primary: { main: "#9c27b0", contrastText: "#ffffff" },
        secondary: { main: "#ba68c8", contrastText: "#000000" }
    },
    typography: {
        useNextVariants: true
    }
});

class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <HashRouter>
                    <Switch>
                        <Route exact path="/" component={PropertiesList} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/settings" component={Settings} />
                        <Route exact path="/reports/edit/:id" component={EditReport} />
                        <Route exact path="/reports/:id" component={ReportsList} />
                        <Redirect to="/" />
                    </Switch>
                </HashRouter>
            </MuiThemeProvider>
        );
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default App;
