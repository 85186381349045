import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import RateReview from "@material-ui/icons/RateReview";

import { withSnackbar } from "notistack";

import { translate } from "react-multi-lang";

import AuthService from "./auth/AuthService";

const styles = theme => ({
    root: {
        width: "100%"
    },
    grow: {
        flexGrow: 1
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    },
    button: {
        margin: theme.spacing(1)
    },
    headerIcon: {
        marginRight: 5
    }
});

class Login extends React.Component {
    constructor() {
        super();

        this.state = {
            username: "",
            password: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.Auth = new AuthService();
    }

    componentDidMount() {
        if (this.Auth.loggedIn()) {
            this.props.history.replace("/");
        }
    }

    render() {
        const { classes, t } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <RateReview className={classes.headerIcon} />
                        <Typography variant="h5" color="inherit" className={classes.grow}>
                            cleaningReportApp
                        </Typography>
                    </Toolbar>
                </AppBar>
                <form noValidate autoComplete="off" onSubmit={event => this.handleFormSubmit(event)}>
                    <TextField required id="username" name="username" label={t("login.Username")} className={classes.textField} margin="normal" onChange={this.handleChange} />
                    <br />

                    <TextField required id="password" name="password" label={t("login.Password")} className={classes.textField} type="password" margin="normal" onChange={this.handleChange} />
                    <br />
                    <Button type="submit" variant="contained" color="primary" className={classes.button}>
                        {t("login.Login")}
                    </Button>
                </form>
            </div>
        );
    }

    handleFormSubmit(e) {
        e.preventDefault();

        this.Auth.login(this.state.username, this.state.password)
            .then(res => {
                this.props.history.replace("/");
            })
            .catch(err => {
                this.props.enqueueSnackbar(this.props.t("login.Error"), { variant: "error", preventDuplicate: true });
            });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired
};

export default translate(withSnackbar(withStyles(styles)(Login)));
